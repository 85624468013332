/* You can add global styles to this file, and also import other style files */

@import "./app/styles/app.scss";
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,100;1,300&display=swap");

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Lato", sans-serif !important;
}

.mat-card-title {
  font-weight: bold;
  color: #38373a;
}

.mat-card-subtitle {
  color: #38373a !important;
  font-family: "Lato", sans-serif;
  font-size: 16px;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #2e4172;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #2e4172;
  color: #2e4172;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: #2e4172;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #2e4172 !important;
}
.table_container {
  background-color: #38373a !important;
}
.table_header {
  background-color: red !important;
}

.hide {
  // display: none;
  background-color: transparent !important;
  .mat-dialog-container {
    background-color: transparent !important;
  }
}
.request-vacation-popup {
  .close {
    float: right;
    color: white;
    background-color: transparent;
    cursor: pointer;
    overflow: hidden;
  }
  .hours-graph-container {
    margin: 0px !important;
  }
  .mat-card-title {
    background-color: #38373a;
    color: white;
    padding: 15px;
    font-weight: 600;
    font-family: "Lato", sans-serif;
    font-size: 17px;
  }
  .mat-card-subtitle {
    margin-left: 21px;
    margin-top: 21px !important;
  }
  .hr-title {
    display: none;
  }
  .mat-dialog-container {
    padding: 0px;
    border-radius: 0px;
    height: auto;
  }
  .mat-calendar-table-header-divider {
    display: none;
  }
}

ekiba-master-table {
  .mat-input-search-wrapper {
    border-radius: 0px !important;
    width: 95%;
  }

  .header {
    letter-spacing: 2px;
    text-align: center;
    text-transform: uppercase;
    color: #f7f7f7;
    font-size: 14px;
    font-weight: 400;
    padding: 9px 0;
    background-color: #38373a !important;
    position: relative;

    .header-title__icon {
      line-height: 23px;
      margin-right: 6px;
      vertical-align: middle;
    }

    .header-title__add-icon {
      top: 4px;
      right: 42px;
      position: absolute;
      font-size: 14px;
      background-image: linear-gradient(to right, #aa3c39, #2576a5);
    }

    .header-title__add {
      position: absolute;
      top: 8px;
      right: 12px;
      font-size: 12px;
      text-transform: none;

      .header-title__add-button {
        height: 32px;
        width: 20px;
        letter-spacing: 0;
        font-size: 12px;
      }
    }

    .header-icon {
      line-height: 23px;
      margin-right: 6px;
      vertical-align: middle;
    }
  }

  .table_container {
    overflow: auto;
    width: 100%;
    border-radius: 0px !important;
    .mat-cell {
      padding: 10px;
    }

    .table {
      width: 100%;
      overflow: auto;
      padding-bottom: 20px;

      .table_header {
        // Paletton: https://paletton.com/#uid=30u0u0kllllaFw0g0qFqFg0w0aF
        background-color: #38373a !important;
      }
    }
  }

  @media (max-width: 600px) {
    body {
      background-position: right bottom;
    }
    .mobile-content {
      display: flex;
      width: 100%;
      min-height: 48px;
      border-radius: 0px !important;

      .mobile-content__label {
        display: flex;
        width: 40%;
        color: white;
        align-items: center;
        justify-content: center;
        background-color: #38373a !important;
      }

      .mobile-content__value {
        display: flex;
        width: 60%;
        justify-content: center;
        align-items: center;
      }
    }

    .mobile-label {
      width: 80px;
      display: inline-block;
      font-weight: bold;
    }

    .toggle-suffix {
      font-size: 18px !important;
    }
  }
}

.error-color {
  color: rgb(202, 44, 44);
}

@keyframes popUp {
  0% {
    opacity: 0;
    transform: translateZ(-100px);
    transform: translate(-50%, -50%);
  }
  80% {
    opacity: 1;
    transform: translateZ(0);
    transform: translate(-50%, -50%);
  }
  100% {
    opacity: 0;
    transform: translateZ(-20px);
    transform: translate(-50%, -50%);
  }
}

.spinner {
  box-sizing: border-box;
  height: 100%;
  left: 50%;
  position: absolute;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.marto20 {
  margin-top: 20px;
}

.marto24 {
  margin-top: 24px;
}

.marto40 {
  margin-top: 40px;
}

.marto60 {
  margin-top: 60px;
}

.marbo20 {
  margin-bottom: 20px;
}

.marbo24 {
  margin-bottom: 24px;
}

.marbo40 {
  margin-bottom: 40px;
}

.marbo60 {
  margin-bottom: 60px;
}

.marri12 {
  margin-right: 12px;
}
