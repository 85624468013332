@import "config/variables";
@import "config/functions";

.volver {
  display: flex;
  width: 50%;
  float: left;
}

.nuevo {
  display: flex;
  justify-content: flex-end;
}

.b_volver {
  color: white;
  background-color: $color_cabecera;
  font-size: 13px;
}

.body {
  /* max-width: 1020px; */
  margin: 0 auto;
  //width: 95%;
  margin-left: 1.5%;
  margin-right: 1.5%;

  .nuevo_usuario {
    display: flex;
    justify-content: flex-end;

    .boton_nu {
      display: flex;
      align-items: center;
      color: white;
      background-color: $color_cabecera;
    }
  }

  .table_container {
    width: 100%;
    //margin-left: 20px;
    margin-top: 20px;

    .table {
      width: 100%;
      border-radius: 12px;
      overflow: hidden !important;

      .table_header {
        background-color: $color_cabecera;
      }

      .columna_final_header {
        display: flex;
        justify-content: flex-end;
        margin-right: 10px;
      }

      .columna_final {
        display: flex;
        justify-content: flex-end;
      }
    }
    .mat-cell {
      font-size: 13px;
    }

    .mat-header-cell {
      color: white !important;
      text-transform: uppercase;
      font-size: 13px;
    }

    .mat-sort-header-arrow {
      color: white !important;
    }

    .mat-row:nth-child(even) {
      background-color: #d4e6f1;
    }

    .mat-row:nth-child(odd) {
      background-color: #ffff;
    }
  }
}

.green-line {
  background-color: #5ca95b !important;
}
.grey-line {
  background-color: #8f8d8d !important;
}
.blue-line {
  background-color: #2576a5 !important;
}
.red-line {
  background-color: #f92380 !important;
}
