$color_fonfo_menu: #387496;
//$color_fondo_opciones: #C6C4C4;
$color_fondo_opciones: #ededed;
//$color_fondo_desplegado: #e3e3e3;
$color_fondo_desplegado: #ededed;

$color_letra_menu: #000000ce;
$color_letra_submenu: #000000ee;

$color_cabecera: #387496;

$color_primary: #2576a5;
$color_secondary: #c5c5c5;
$color_dark: #8f8d8d;
$color_error: rgb(236, 34, 34);
