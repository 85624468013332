/* FLEXBOX */
@mixin flexbox($args:"") {
  display: -webkit-box #{$args};  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox #{$args};  /* TWEENER - IE 10 */
  display: -webkit-flex #{$args}; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex #{$args};         /* NEW, Spec - Firefox, Chrome, Opera */
}

@mixin flex($args) {
  -webkit-box-flex: $args;   /* OLD - iOS 6-, Safari 3.1-6 */
  -webkit-flex: $args;       /* Safari 6.1+. iOS 7.1+, BB10 */
  -ms-flex: $args;           /* IE 10 */
  flex: $args;               /* NEW, Spec - Firefox, Chrome, Opera */
}

@mixin flex-direction($args) {
  -ms-flex-direction: $args; /* IE10 */
  -webkit-flex-direction: $args; /* Safari / iOS */
  flex-direction: $args;
}

@mixin flex-order($args) {
  -ms-order: $args; /* IE10 */
  -webkit-order: $args; /* Safari / iOS */
  order: $args;
}

@mixin flex-align($args){
  -webkit-box-align: $args;
  -webkit-align-items: $args;
  -moz-box-align: $args;
  -ms-flex-align: $args;
  align-items: $args;
}

@mixin justify-content($args) {
  -webkit-justify-content: $args;
  justify-content: $args;
}

@mixin flex-wrap($args) {
  -ms-flex-wrap: $args; /* IE10 */
  -webkit-wrap: $args; /* Safari / iOS */
  flex-wrap: $args;
}

@mixin appearance($args) {
  -webkit-appearance: $args; /*Safari/Chrome*/
  -moz-appearance: $args; /*Firefox*/
  -ms-appearance: $args; /*IE*/
  -o-appearance: $args; /*Opera*/
  appearance: $args;

  -webkit-border-radius: 0;
}

@mixin flex-grow($args) {
  -webkit-flex-grow: $args;
  -ms-flex-grow: $args;
  flex-grow: $args;
}

@mixin align-self($args) {
  -webkit-align-self: $args; /* Safari 7.0+ */
  align-self: $args;
}

@mixin align-items($args) {
  -webkit-align-items: $args; /* Safari 7.0+ */
  align-items: $args;
}

// Helper mixin to build arrow with css.
@mixin arrow($size, $direction, $color) {
  display: inline-block;
  width  : 0;
  height : 0;

  @if $direction == "up" or $direction == "down" {
    border-right: #{$size}px solid transparent;
    border-left : #{$size}px solid transparent;

    @if $direction == "up" {
      border-bottom: #{$size}px solid $color;
    } @else {
      border-top: #{$size}px solid $color;
    }
  } @else if $direction == "left" or $direction == "right" {
    border-top   : #{$size}px solid transparent;
    border-bottom: #{$size}px solid transparent;

    @if $direction == "left" {
      border-right: #{$size}px solid $color;
    } @else {
      border-left: #{$size}px solid $color;
    }
  }
}

@mixin writing-mode($args) {
  -webkit-writing-mode: $args;
  -moz-writing-mode: $args;
  -ms-writing-mode: $args;
  writing-mode: $args;
}


// Px to Em
@function em( $px, $context: $base_font_size ) {
  @return #{$px/$context}em
}

// Px to Rem
@function rem( $px, $context: $base_font_size ) {
  @return #{$px/$context}rem
}

@mixin font($style, $weight, $size, $family ) {
  font-style: $style;
  font-weight: $weight;
  font-family: $family;
  font-size: ($size)+px;
  font-size: rem($size);
}

@mixin font-size($size) {
  font-size: ($size)+px;
  font-size: rem($size);
}

@mixin opacity($alpha) {
  opacity: $alpha;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$alpha*100})";
}

@mixin bdrs( $radius ) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin trs( $args... ) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -o-transition: $args;
  transition: $args;
}
/**/
@mixin trs-inoutback($time) {
  -webkit-transition: all $time cubic-bezier(0.680, 0, 0.265, 1); /* older webkit */
  -webkit-transition: all $time cubic-bezier(0.680, -0.550, 0.265, 1.550);
  -moz-transition: all $time cubic-bezier(0.680, -0.550, 0.265, 1.550);
  -o-transition: all $time cubic-bezier(0.680, -0.550, 0.265, 1.550);
  transition: all $time cubic-bezier(0.680, -0.550, 0.265, 1.550); /* easeInOutBack */

  -webkit-transition-timing-function: cubic-bezier(0.680, 0, 0.265, 1); /* older webkit */
  -webkit-transition-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550);
  -moz-transition-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550);
  -o-transition-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550);
  transition-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550); /* easeInOutBack */
}
@mixin trf( $args ) {
  -webkit-transform: $args;
  -moz-transform: $args;
  -o-transform: $args;
  transform: $args;
}
@mixin trfo( $args : "50% 50%" ) {
  -webkit-transform-origin: $args;
  -moz-transform-origin: $args;
  -o-transform-origin: $args;
  -ms-transform-origin: $args;
  transform-origin: $args;
}
@mixin bxsz($arg) {
  -webkit-box-sizing: $arg;
  -moz-box-sizing: $arg;
  -o-box-sizing: $arg;
  box-sizing: $arg;
}

@mixin trs-delay($args){
  -webkit-transition-delay: $args; /* Safari */
  transition-delay: $args;
}

// Mixin that returns rgba colors and
// the propietary syntax for IE8
@mixin back_rgba_color($color, $alpha) {
  $rgba: rgba($color, $alpha);
  $ie-hex-str: ie-hex-str($rgba);
  background-color: transparent;
  background-color: $rgba;
  -ms-filter:"progid:DXImageTransform.Microsoft.gradient(startColorstr=#{$ie-hex-str},endColorstr=#{$ie-hex-str})";
  zoom: 1;
}

@mixin prefix($prop, $vals) {
  -webkit-#{$prop}: $vals;
  -moz-#{$prop}: $vals;
  -o-#{$prop}: $vals;
  #{$prop}: $vals;
}

@mixin arrow_box($width, $height, $position, $size, $border, $color, $bcolor) {
  width: $width;
  height: $height;
  position: relative;
  background: $color;
  border: #{$border}px solid $bcolor;
  &:after, &:before {
    @if $position == "top" { bottom: 100%; left: 50%; }
    @else if $position == "right" { left: 100%; top: 50%; }
    @else if $position == "left" { right: 100%; top: 50%; }
    @else if $position == "bottom" { top: 100%; left: 50%; }
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(106, 60, 213, 0);
    border-width: #{$size}px;
    @if $position == "top" { border-bottom-color: $color; margin-left: -#{$size}px; }
    @else if $position == "right" { border-left-color: $color; margin-top: -#{$size}px; }
    @else if $position == "left" { border-right-color: $color; margin-top: -#{$size}px; }
    @else if $position == "bottom" { border-top-color: $color; margin-left: -#{$size}px; }
  }
  &:before {
    border-color: rgba(194, 225, 245, 0);
    $size6: $size + round($border*1.5);
    border-width: #{$size6}px;
    @if $position == "top" { border-bottom-color: $bcolor; margin-left: -#{$size6}px; }
    @else if $position == "right" { border-left-color: $bcolor; margin-top: -#{$size6}px; }
    @else if $position == "left" { border-right-color: $bcolor; margin-top: -#{$size6}px; }
    @else if $position == "bottom" { border-top-color: $bcolor; margin-left: -#{$size6}px; }
  }
}

/* Icono del menu del movil */
@mixin burguer($ancho, $alto, $separacion, $color) {
  position: relative;
  z-index: 500;
  width: #{$ancho}px;
  height: #{round($alto*3 + $separacion*2)}px;
  display: inline-block;
  vertical-align: middle;

  .icon-top-line, .icon-middle-line, .put-in-middle, .icon-bottom-line {
    position: absolute;
    left: 0;
    display: block;
    width: 100%;
    height: #{$alto}px;
    background-color: $color;
    @include trs( all 0.5s ease );
  }

  .icon-top-line { top: 0; }
  .icon-middle-line, .put-in-middle { top: #{$alto+$separacion}px; }
  .icon-bottom-line { bottom: 0; }
  .rotate-45deg { @include trf( rotate(45deg) ); }
  .rotate-45deg-reverse { @include trf( rotate(-45deg) ); }
}

@mixin container_max($max_width) {
  width: 100%;
  max-width: $max_width;
  margin: 0 auto;
}

@mixin blink_me($time, $count) {
  .blink_me {
    -webkit-animation-name: blinker;
    -webkit-animation-duration: $time;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: $count;

    -moz-animation-name: blinker;
    -moz-animation-duration: $time;
    -moz-animation-timing-function: linear;
    -moz-animation-iteration-count: $count;

    animation-name: blinker;
    animation-duration: $time;
    animation-timing-function: linear;
    animation-iteration-count: $count;
  }

  @-moz-keyframes blinker {
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
  }

  @-webkit-keyframes blinker {
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
  }

  @keyframes blinker {
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
  }
}

@mixin loaded($time, $count) {
  &.is-loaded {
    -webkit-animation-name: loaded;
    -webkit-animation-duration: $time;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: $count;

    -moz-animation-name: loaded;
    -moz-animation-duration: $time;
    -moz-animation-timing-function: linear;
    -moz-animation-iteration-count: $count;

    animation-name: loaded;
    animation-duration: $time;
    animation-timing-function: linear;
    animation-iteration-count: $count;
  }

  @-moz-keyframes loaded {
    0% {
      opacity: 0.0;
      //@include trf(translateX(10%));
    }
    100% {
      opacity: 1.0;
      //@include trf(translateX(0%));
    }
  }

  @-webkit-keyframes loaded {
    0% {
      opacity: 0.0;
      //@include trf(translateX(10%));
    }
    100% {
      opacity: 1.0;
      //@include trf(translateX(0%));
    }
  }

  @keyframes loaded {
    0% {
      opacity: 0.0;
      //@include trf(translateX(10%));
    }
    100% {
      opacity: 1.0;
      //@include trf(translateX(0%));
    }
  }
}

@mixin calc($prop, $args) {
  #{$prop} : -moz-calc(#{$args});
  #{$prop} : calc(#{$args});
}
